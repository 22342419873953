import React, { useEffect, useState } from "react";

function Select(props) {
  const {
    name,
    btnClicked,
    getValuesFn,
    showOtherInstitute,
    hideOtherInstitute,
  } = props;

  const [value, setValue] = useState(props.value);
  const [selectedValue, setSelectedValue] = useState("");
  const [filterInstitutesData, setFilterInstitutesData] = useState([]);
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    //
  }, [btnClicked]);

  useEffect(() => {
    setFilterInstitutesData(props.instituteList);
  }, [props.instituteList]);

  const updateSearch = (e) => {
    const filteredInstitutes = props.instituteList.filter((data) => {
      return data.toLowerCase().includes(e.target.value);
    });

    setFilterInstitutesData(filteredInstitutes);
  };

  const showListHandler = () => {
    setShowList(!showList);
  };

  const optionClickHandler = (option) => {
    setSelectedValue(option);
    setShowList(false);
    setValue(option);

    if (option === "Other") {
      showOtherInstitute();
    } else {
      hideOtherInstitute();
      const data = {
        value: option,
        id: name,
      };

      getValuesFn(data);
    }
  };

  return (
    <React.Fragment>
      <input
        id="lead_type"
        onClick={showListHandler}
        name={name}
        className="bb-training-form-input bb-training-form-select-institute"
        readOnly={true}
        value={selectedValue}
        placeholder="Select"
      />
      {showList && (
        <div className="bb-training-form-list">
          <div className="bb-training-form-list-content">
            <div className="bb-training-form-list-search">
              <input
                type="text"
                onChange={updateSearch}
                className="bb-training-form-input"
              />
            </div>
            <div className="bb-training-form-list-wrap">
              {filterInstitutesData.length > 0 ? (
                <div className="bb-training-form-list-item disabled">
                  select
                </div>
              ) : (
                <div className="bb-training-form-list-item disabled">
                  Type "Other" (If Institute not in the list)
                </div>
              )}

              <div
                onClick={() => optionClickHandler("Other")}
                aria-hidden="true"
                className={
                  "bb-training-form-list-item " +
                  (filterInstitutesData.length <= 0 ? "active" : "")
                }
              >
                Other
              </div>
              {filterInstitutesData.map((option, k) => (
                <div
                  key={k}
                  onClick={() => optionClickHandler(option)}
                  aria-hidden="true"
                  className={
                    "bb-training-form-list-item " +
                    (option === value ? "active" : "")
                  }
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {btnClicked && !value && <p className="bb-error-msg">can't be blank</p>}
    </React.Fragment>
  );
}

export default Select;
